@use "../mixins";

.notifications_page__container {
  display: grid;
  grid-template-columns: auto 1fr;

  & > .doc_rep_content {
    overflow-x: auto;
    padding: 24px;
    box-shadow: -5px 0px 6px rgb(0 0 0 / 10%);
  }

  .notifications_header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    & > button {
      @include mixins.default_button(14px, 24px);
      white-space: nowrap;
      padding: 8px 16px;
    }
  }

  .search-bar__container {
    width: auto;
    margin-bottom: 0;
  }
}

.unread__container {
  display: flex;
  flex-wrap: wrap;

  margin: 1rem 0;

  & > .unread_notif {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0 1rem;
    border-right: 1px solid lightgrey;

    & > h6 {
      margin: 0;
      margin-right: 12px;
      color: var(--dark_grey);
      font-weight: 600;
      font-size: 20px;
    }

    & > .qty {
      min-width: 60px;
      background-color: var(--status_error);
      color: var(--white);
      border-radius: 100px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
    }
  }

  & > .table_action_btn {
    margin: 0;
  }
}

.ntf_date__container {
  letter-spacing: 1px;

  .unread_notification {
    display: flex;
    align-items: center;
    color: var(--status_error);

    & > span {
      margin-left: 8px;
    }
  }
}

.ntf_description__container {
  span {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    & > a {
      color: var(--blue);
      font-style: italic;
    }
  }

  .read_more__btn {
    padding: 0;
    color: var(--blue);
    font-weight: 600;
    font-size: inherit;
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }
  }
}

.visit_btn {
  color: var(--blue);
  font-weight: 600;
  font-size: 16px;

  & > span {
    margin-left: 6px;
    text-decoration-line: underline;
  }
}
