@use 'create_account';
@use 'alert-window';

.welcome_wrapper {
  min-height: 100vh;
  background-color: var(--white);
}

.welcome_header {
  display: flex;
  justify-content: center;
  padding: 24px 0;

  background: #f9f9f9;
  border-bottom: 1px solid #cad1e1;
}

.login_header-logo {
  height: 40px;
  width: 165px;
  background: url('/assets/images/logo-full.svg');
  background-repeat: no-repeat;
}

.welcome_content {
  padding: 12px 50px;

  background: url('/assets/images/laptop-wDashboard_grey.png') no-repeat 0;
  background-position: top 0 right 0;

  & > .description {
    max-width: 630px;
    padding: 24px 0;
    border-bottom: 1px solid var(--light_grey);

    & > * {
      margin: 0;
      text-transform: uppercase;
    }

    & > h2 {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.2em;
      color: var(--dark_grey);
    }

    & > h1 {
      font-weight: bold;
      font-size: 48px;
      color: var(--light_blue);
    }
  }

  & > .provide_info {
    margin: 24px 0;
    font-weight: 500;
    font-size: 12px;
    color: var(--dark_grey);
  }
}
