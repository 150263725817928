section {
  border-radius: 8px;
  background: var(--white);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

  & > h2 {
    margin: 0;
    padding: 24px 33px;
    border-radius: 8px 8px 0 0;
    background: var(--light_blue);
    color: var(--white);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.doc_rep {
  &__container {
    display: grid;
    grid-template-columns: auto 1fr;

    & > .doc_rep__content {
      overflow-x: auto;
      padding: 24px;
      box-shadow: -5px 0px 6px rgba(0, 0, 0, 0.1);

      .document_name {
        font-weight: 600;
        font-size: 16px;
        color: var(--grey);
      }

      .document_status {
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;

        svg {
          position: relative;
          top: 7px;
          width: 17px;
          height: 17px;
          padding: 5px;
          border-radius: 100px;
          background: #40994e;
        }
      }
    }
  }

  &__spaces {
    display: flex;
    grid-gap: 60px;
    flex-wrap: wrap;
    justify-content: center;

    padding: 60px 30px 30px;

    p {
      color: var(--blue);
      text-align: center;
      max-width: 10ch;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
    }

    & > .doc_rep__option {
      padding: 40px;
      padding-bottom: 70px;

      flex: 0 1 300px;
      display: flex;
      flex-direction: column;
      align-items: center;

      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      & > .manage_btn {
        background: var(--light_blue);
        color: var(--white);
        border-radius: 100vh;
        padding: 12px 24px;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.04em;
        text-transform: uppercase;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  & > p {
    max-width: 1000px;
    padding: 50px 0;
    margin: 0 auto;
    text-align: center;
    color: var(--medium_grey);
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
  }
}

.page_route__container {
  padding-bottom: 24px;
  color: var(--dark_grey);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  & > a,
  & > button {
    color: var(--blue);
    text-decoration: underline;

    &:hover {
      opacity: 0.9;
      text-decoration: none;
    }
  }

  & > span {
    margin: 1ch;
  }
}
