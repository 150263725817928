.help_page {
  min-height: calc(100vh - 245px);

  & > div {
    padding: 40px 30px;

    h1 {
      margin: 0;
      font-size: 30px;
      letter-spacing: 1px;
      color: var(--blue);
    }
  }

  .tabs_container {
    margin-top: 60px;

    .tab {
      box-shadow: none;
      border: 1px solid var(--medium_grey);
      border-bottom: none;
    }
  }

  .help_page_content {
    max-width: 1400px;
    min-height: 700px;

    padding: 20px 30px;

    border-radius: 8px;
    border: 1px solid var(--medium_grey);

    box-sizing: border-box;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);

    overflow: hidden;

    .section_title {
      font-size: 20px;
      color: var(--blue);
    }

    .help_overview {
      border-bottom: 1px solid var(--medium_grey);
      padding-bottom: 20px;
      margin-bottom: 40px;

      .description {
        max-width: 900px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--dark_grey);
      }
    }

    .question_row_wrapper {
      max-width: 1000px;
      position: relative;
      margin-bottom: 26px;

      &::before {
        position: absolute;
        z-index: 1;
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 6px;
        background-color: var(--blue);
        border-radius: 4px 0px 0px 4px;
      }

      .expander {
        position: relative;
        width: 18px;
        height: 4px;

        margin-right: 16px;

        border-radius: 8px;
        background-color: var(--blue);

        &.open {
          &::after {
            position: absolute;
            top: -7px;
            left: 7px;
            content: '';
            background-color: inherit;
            width: 4px;
            height: 18px;
            border-radius: 8px;
          }
        }
      }

      .highlighted {
        font-weight: 800;
        margin-right: 8px;
      }

      .question_row {
        display: flex;
        align-items: center;

        width: 100%;

        padding: 18px 23px;
        border-radius: 4px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        font-weight: 600;
        font-size: 16px;
        line-height: 36px;
        color: var(--blue);
      }

      .answer_row {
        position: relative;

        display: none;
        padding: 16px 57px; // .question_row padding 23px + expander width 18px + grid-gap 16px

        border-radius: 4px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

        background: linear-gradient(180deg, #f3f5f8 0%, rgba(243, 245, 248, 0) 70px);

        &.show {
          display: block;
        }

        &::after {
          content: '';
          position: absolute;
          background-color: var(--white);
          border-radius: 4px;
          width: 100%;
          height: 20px;
          top: -20px;
          left: 0;
        }

        .short_answer {
          font-weight: 600;
          font-size: 16px;
          line-height: 36px;
          color: var(--blue);
        }

        .long_answer {
          padding-left: 20px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: var(--dark_grey);
        }
      }
    }
  }
}
