@use 'login';
@use 'navbar';
@use 'dashboard';
@use 'header';
@use 'footer';
@use 'table';
@use 'modal';
@use 'components';
@use 'document-repository';
@use 'product-catalog';
@use 'terms_n_conditions';
@use 'settings';
@use 'notifications';
@use 'welcome';
@use 'homepage';
@use 'help';

:root {
  --white: #ffffff;
  --grey: #696969;
  --light_grey: #bcc4d8;
  --medium_grey: #939bae;
  --dark_grey: #4b515d;
  --blue: #006298;
  --light_blue: #03abe3;
  --status_error: #d61d1d;
  --orange: #ea824e;
  --ice_blue: #f3f5f8;
}

body {
  margin: 0;
  min-height: 100vh;
  background-color: #e2e2e2;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  padding: 24px;
}
main.nav-open {
  margin-left: 72px;
}

button {
  padding: 0;
  cursor: pointer;
  border: none;
  background: inherit;
  font-family: inherit;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
