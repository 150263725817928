.dropdown_btn {
  position: relative;

  & > button {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;

    padding: 0 12px;
    border-radius: 8px;

    color: var(--white);
    background-color: var(--light_blue);

    & > .name {
      padding: 13px 8px 13px 0;
      margin-right: 8px;

      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      border-right: 2px solid var(--white);
    }

    & > svg {
      transform: rotate(90deg);
    }

    &:hover:enabled {
      opacity: 0.9;
    }

    &:disabled {
      color: var(--dark_grey);
      background-color: var(--light_grey);
      cursor: not-allowed;

      & > .name {
        border-right: 2px solid var(--ice_blue);
      }

      & > svg {
        fill: var(--dark_grey);
      }
    }
  }

  & > .menu__container {
    z-index: 1;
    min-width: 100%;
    position: absolute;

    padding: 20px;
    background: var(--white);

    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--light_blue);
    border-radius: 8px;
  }
}
