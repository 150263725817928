@use '../mixins';

@mixin header_cell {
  display: flex;
  align-items: center;
}

.table-wrapper {
  display: grid;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    border-collapse: separate;
    border-spacing: 0 8px;
    padding: 0 20px;
    width: 100%;
    table-layout: auto;

    font-weight: 500;
    font-size: 16px;
    color: var(--dark_grey);

    thead {
      th {
        padding: 0 18px;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        color: var(--dark_grey);

        & > .header-cell {
          @include header_cell;

          .sorting {
            margin-left: 6px;
            display: inherit;
            flex-direction: column;

            & > svg {
              &:last-child {
                margin-top: 3px;
                transform: rotate(180deg);
              }
            }
          }
        }

        .header_checkbox {
          @include header_cell;
          position: relative;
          padding: 9px;

          & > svg {
            margin-left: 4px;
            transform: rotate(180deg);
            cursor: pointer;
          }

          & > span,
          & > svg {
            z-index: 1;
          }

          &__content {
            display: none;
            top: 0;
            left: 0;
            position: absolute;
            background-color: var(--white);
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;

            & > ul {
              min-width: 110px;
              margin-top: 50px;

              & > li {
                padding: 9px;

                & > button {
                  cursor: not-allowed;
                  padding: 0;
                  color: var(--grey);
                  font-weight: 600;
                  font-size: 14px;
                }
              }

              & > li.active {
                & > button {
                  color: var(--blue);
                }

                &:hover {
                  background-color: var(--blue);

                  & > button {
                    cursor: pointer;
                    color: var(--white);
                  }
                }
              }
            }
          }

          &__content.open {
            display: block;
          }

          &:hover {
            background-color: var(--ice_blue);
            border-radius: 8px;
          }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid var(--white);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        td {
          padding: 18px;

          & > .row_checkbox {
            padding: 0 9px;
          }
        }
      }

      .expander_container {
        vertical-align: top;
        width: 0;
      }

      .expandable {
        position: relative;
        z-index: 1;
        transition: all 300ms;

        &:hover {
          box-shadow: 0px 0px 10px var(--blue);

          .expander_container {
            &::after {
              background-color: var(--light_blue);
            }

            .expander div {
              background-color: var(--light_blue);
            }
          }
          .doc_type_container .doc_name {
            color: var(--light_blue);
          }
        }
      }

      .sub-component {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          background-color: white;
          width: 100%;
          height: 20px;
          top: -20px;
          left: 0;
        }

        & > td {
          padding: 0;
        }
      }

      .loading {
        @include mixins.loading_placeholder;
        height: 24px;
      }

      .no_data {
        box-shadow: none;
        text-align: center;
      }
    }

    .table_row_select {
      $blue: #0078ba;
      $grey: #cad1e1;
      $size: 28px;

      &:hover {
        & > input[type='checkbox'] {
          & + span {
            border: 2px solid var(--blue);
          }

          &:indeterminate {
            & + span {
              &::before {
                background-color: $blue;
              }
            }
          }
        }
      }

      & > input[type='checkbox'] {
        & + span {
          width: $size;
          height: $size;
          box-sizing: border-box;
          border: 2px solid $grey;
          border-radius: 4px;
        }

        &:checked {
          & + span {
            border: 2px solid $blue;
            background-color: $blue;
          }
        }

        &:indeterminate {
          & + span {
            &::before {
              content: '';
              border-radius: 3px;
              width: 15px;
              height: 4px;
              background-color: $grey;
            }
          }
        }
      }
    }
  }
}

.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;

  & > button {
    width: 30px;
    height: 30px;
    margin: 3px;
  }

  & > .page_step {
    &:hover:enabled {
      background-color: var(--ice_blue);
      border-radius: 50%;
    }

    &:disabled {
      cursor: not-allowed;

      & > svg {
        fill: var(--grey);
      }
    }
  }

  & > .prev_page {
    transform: rotate(180deg);
  }

  & > .pagination_item {
    padding: 2px;
    background-color: var(--ice_blue);
    border: 1px solid #cad1e1;
    border-radius: 100px;
    font-weight: 600;
    color: var(--blue);
    font-size: 14px;

    &:not(.active) {
      &:hover {
        border: 1px solid var(--blue);
        background-color: #e6f6fe;
      }
    }
  }

  & > .pagination_item.active {
    border: 1px solid var(--blue);
    color: var(--white);
    background-color: var(--blue);
  }
}

.loader_modal_wrapper {
  z-index: 2;
  position: absolute;
  place-self: center;

  background-color: var(--white);
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  padding: 20px;

  h1 {
    margin: 0;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: var(--dark_grey);
  }

  .loader {
    display: block;
    margin: auto;

    @include mixins.gradient_loader(68px, 10px);
  }
}
