.menu-btn {
  background-color: var(--white);
  border: 0;
  padding: 24px;
  padding-right: 30px;
  border-radius: 0 8px 8px 0;
  cursor: pointer;

  &__burger {
    width: 18px;
    height: 3px;
    display: block;
    position: relative;
    background-color: var(--grey);
    border-radius: 100vh;
    transition: all 500ms ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 24px;
      height: 3px;
      background-color: var(--grey);
      border-radius: 100vh;
      transition: all 500ms ease-in-out;
    }

    &::before {
      transform: translateY(-7px);
    }

    &::after {
      transform: translateY(7px);
    }
  }
}

.nav {
  z-index: 2;
  height: 100%;
  position: fixed;
  top: 0;
  padding-top: 12px;
  background-color: var(--white);
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;

  & > .menu-btn {
    margin-top: 16px;
    padding: 16px 26px;
  }

  & > .divider {
    margin: 16px 8px;
    border-bottom: 1px solid #e4e4e4;
  }

  .nav__list {
    & > .nav__item {
      position: relative;
      padding: 24px;

      &:hover {
        .nav__link svg {
          fill: var(--light_blue);
        }
      }

      .nav__link {
        text-decoration: none;
        color: var(--grey);

        svg {
          transition: all 300ms;
        }
      }
    }

    & > .nav__item.selected {
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 8px;
        height: 48px;
        right: 0;
        top: 12px;
        background: var(--light_blue);
        border-radius: 5px 0px 0px 5px;
      }
    }
  }
}

nav.nav-open {
  transform: translateX(0);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

  .menu-btn {
    &__burger {
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;
    }

    &__burger::before {
      transform: rotate(45deg) translate(35px, -35px);
    }

    &__burger::after {
      transform: rotate(-45deg) translate(35px, 35px);
    }
  }
}

.tooltip-container {
  position: relative;

  .tooltip-box {
    z-index: 1;
    display: none;
    padding: 2px;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: calc(100% + 10px);
    background-color: var(--light_blue);
    border-radius: 4px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
    white-space: nowrap;

    &::before {
      content: '';
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 6px;
      margin-left: -6px;
      left: -6px;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: var(--light_blue);
    }

    & > button {
      padding: 0 8px;
      color: var(--white);
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;

      & > a {
        display: flex;
        align-items: center;
      }

      & svg {
        transform: rotate(90deg);
      }

      &:hover {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }
}

.tooltip-container.active {
  &::after {
    content: '';
    position: absolute;
    top: -24px;
    left: 24px;
    height: 76px;
    width: 2em;
  }

  .tooltip-box {
    display: flex;
  }
}
