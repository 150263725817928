@use '../mixins';

@use 'alerts';
@use 'update-profile';
@use 'service-plans';
@use 'account_mgmt';
@use 'invite_users';
@use 'edit-role';

.settings {
  & > div {
    display: grid;
    grid-template-columns: auto 1fr;

    & > .settings__container {
      overflow-x: auto;
      padding: 40px;
      box-shadow: -5px 0px 6px rgb(0 0 0 / 10%);

      h3 {
        margin: 0;
        color: var(--blue);
        font-weight: bold;
        font-size: 30px;
        letter-spacing: 1px;
      }

      .description {
        max-width: 70ch;
        margin: 0;
        margin-top: 12px;
        font-weight: 500;
        font-size: 18px;
        color: var(--grey);
      }

      & > .divider {
        padding: 12px 0;
        border-bottom: 1px solid var(--ice_blue);
      }
    }
  }
}

.cancel_btn {
  display: block;
  letter-spacing: 0.02em;

  @include mixins.link_text(14px, 500, 17px);
  @include mixins.text_link_btn();
}
