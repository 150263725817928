@use '../mixins';

@mixin alert_window {
  padding: 10px 18px;
  background-color: var(--white);
  box-shadow: -2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;

  h3 {
    margin: 0;
    color: var(--blue);
    font-weight: bold;
    font-size: 24px;
  }

  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

@mixin edit_action_button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px 0px 0px 100px;

  color: var(--white);

  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    opacity: 0.9;
  }
}

.product_details__container {
  .loading {
    @include mixins.loading_placeholder;
  }

  .add_info_btn {
    padding: 0;
    color: var(--status_error);
    font-weight: 600;
    text-decoration: underline;
  }

  .edit_info_input {
    padding: 0 8px;

    box-sizing: border-box;
    border: 2px solid var(--orange);
    border-radius: 8px;

    font-family: inherit;
    font-size: 14px;

    &:focus {
      outline: none;
      border: 2px solid var(--blue);
    }
  }

  textarea {
    resize: none;
    width: 100%;
    max-height: 200px;
  }
}

.showing_total_items {
  padding-top: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: var(--medium_grey);
}

.product_description {
  display: flex;
  flex-wrap: wrap;

  padding: 30px;
  padding-bottom: 0;

  & > .smooth_image_wrapper {
    width: 350px;
    height: 350px;
  }

  &__content {
    flex: 1 1 500px;
    padding: 0 24px;

    & > h3 {
      margin: 0;
      margin-bottom: 15px;
      color: var(--dark_grey);
      font-weight: bold;
      font-size: 32px;
      letter-spacing: 1px;

      .loading {
        height: 32px;
      }
    }

    .sub_header {
      max-width: 470px;
      margin-bottom: 20px;

      display: flex;
      grid-gap: 8px;
      align-items: center;
      justify-content: space-between;

      .info_icon {
        cursor: default;
        margin: 0;
      }

      & > span {
        padding: 6px 20px;

        background-color: var(--blue);
        color: var(--white);

        border-radius: 100px;

        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
      }

      & > .complete_percentage {
        flex: 1;

        padding: 4px 20px;

        background-color: #f9f9f9;
        border-radius: 100px;
      }

      &.loading {
        height: 24px;
      }
    }

    & > .details {
      padding: 14px;
      margin-bottom: 20px;
      background-color: #f9f9f9;
      border-radius: 8px;

      &.loading {
        height: 100px;
      }

      ul {
        column-width: 300px;

        margin: 0;
        margin-left: 2em;

        list-style: disc;
        color: var(--dark_grey);

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1px;

        & > li span {
          color: var(--dark_grey);
          font-weight: 600;
          letter-spacing: normal;
        }
      }
    }

    h6 {
      margin: 0;
      margin-bottom: 8px;
      color: var(--blue);
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .description_container {
      .description {
        max-width: 900px;
        margin: 0;

        color: var(--dark_grey);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        white-space: pre-wrap;
        letter-spacing: 0.02em;
      }

      &.loading {
        height: 48px;
      }
    }
  }
}

.edit_details_btns {
  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .edit_details {
    display: flex;
    align-items: center;

    padding: 12px;

    @include edit_action_button();
    background-color: var(--light_blue);

    & > svg {
      margin-right: 6px;
    }
  }
  .edit_details.save {
    background-color: var(--orange);
  }
  .edit_details.loader {
    width: 200px;

    & > div {
      @include mixins.submit_loader(12px);
    }
  }

  .cancel_edit {
    position: relative;
    top: 20px;

    padding: 5px 10px;

    @include edit_action_button();
    background-color: var(--status_error);
  }
}

.save_progress__btn {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  color: var(--blue);
  font-weight: 500;
  font-size: 14px;

  @include mixins.text_link_btn;
}

.success_alert {
  @include alert_window();
  border: 3px solid var(--light_blue);
  border-right: none;

  h5 {
    color: var(--blue);
  }

  p {
    margin-bottom: 0;
    color: var(--medium_grey);
    font-weight: 500;
    font-size: 12px;
  }
}

.error_alert {
  @include alert_window();
  min-width: 320px;

  border: 3px solid var(--status_error);
  border-right: none;

  h3 {
    color: var(--status_error);
  }
}
