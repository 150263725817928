.modal_wrapper {
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.8);
  z-index: 999;

  & > .modal {
    max-width: 60%;
    background: var(--white);
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    & > .header {
      display: flex;
      align-items: flex-start;

      min-width: 500px;
      padding: 12px;
      margin: -1px;
      margin-bottom: 0;

      background-color: var(--light_blue);
      border-radius: 8px 8px 0px 0px;

      div {
        padding: 0 16px;

        h1,
        h5 {
          margin: 0;
          color: var(--white);
          font-weight: 600;
        }

        h1 {
          font-size: 18px;
        }

        h5 {
          max-width: 500px;
          font-size: 14px;
        }
      }

      & > button {
        margin-top: 3px;
        margin-left: auto;
      }
    }

    .modal_header {
      padding: 16px 16px 0;
      text-align: right;
    }
  }
}
