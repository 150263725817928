.page_sidebar__container {
  display: flex;
  flex-direction: column;

  width: 72px;
  min-height: calc(100vh - 315px);
  background-color: var(--white);
  border-bottom-left-radius: 8px;

  overflow: hidden;

  transition-property: background-color, width;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  & > .sidebar_btn {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    overflow: hidden;

    margin-top: 18px;
    padding: 12px 0;
    border-radius: 8px 0 0 8px;

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;

    & > * {
      transition-property: fill, stroke, color;
      transition-duration: 300ms;
      transition-timing-function: ease-in-out;

      fill: var(--dark_grey);
      color: var(--dark_grey);

      &:not(:first-child) {
        display: none;
      }
    }

    & > .strokedIcon {
      fill: none;
      stroke: var(--dark_grey);
    }

    & > svg {
      place-self: center;
    }

    & > span {
      max-width: 10ch;
      text-align: left;
    }

    &:hover {
      & > * {
        &:not(.strokedIcon) {
          color: var(--light_blue);
          fill: var(--light_blue);
        }
      }

      & > .strokedIcon {
        stroke: var(--light_blue);
      }
    }
  }

  & > .active {
    & > span {
      color: var(--light_blue);
    }

    & > svg {
      fill: var(--light_blue);
    }

    & > .strokedIcon {
      stroke: var(--light_blue);
    }
  }

  .toggler {
    align-self: center;
    margin-top: 12px;
    padding: 20px 12px !important;

    & > .sidebar_burger {
      width: 24px;
      height: 3px;
      display: block;
      position: relative;
      background-color: var(--dark_grey);
      border-radius: 100vh;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: inherit;
        height: inherit;
        background-color: var(--dark_grey);
        border-radius: 100vh;
        transition: transform 500ms ease-in-out;
      }

      &::before {
        transform: translateY(-7px);
      }

      &::after {
        transform: translateY(7px);
      }
    }
  }
}

.page_sidebar__container.open {
  background-color: var(--ice_blue);
  width: 250px;

  & > .sidebar_btn {
    grid-template-columns: 1fr minmax(10%, 60%) 15%;

    margin-left: 10px;
    padding-left: 9px;

    & > * {
      display: block;
    }
  }

  & > .active {
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: var(--white);

    &::after {
      content: '';
      position: absolute;
      right: -10px;
      height: 100%;
      width: 10px;
      background: var(--white);
    }
  }

  .toggler {
    align-self: flex-end;

    & > .sidebar_burger {
      background-color: transparent;

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
