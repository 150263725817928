@use '../mixins';

.search-bar__container {
  display: flex;
  flex-wrap: wrap;
  justify-self: start;
  align-self: center;
  align-items: center;
  grid-gap: 1rem;

  width: 100%;

  .search-bar {
    display: flex;
    flex-direction: row;

    padding: 5px;
    width: 50%;

    background-color: var(--white);
    border: 1px solid var(--light_blue);
    border-radius: 30px;

    & > button {
      min-width: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: var(--light_blue);

      &:hover {
        opacity: 0.9;
      }
    }

    & > input {
      flex-grow: 2;

      margin-left: 8px;
      color: var(--blue);
      background-color: rgba($color: #fff, $alpha: 0);
      border: none;

      font-family: inherit;
      font-weight: 600;
      font-size: 18px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: var(--grey);
        font-weight: 500;
      }
    }
  }

  .loader {
    @include mixins.submit_loader(20px, 3px, var(--blue));
    margin: 0;
  }

  .search_count {
    display: inline-block;
    padding: 8px 16px;

    border-left: 1px solid var(--light_grey);
    border-right: 1px solid var(--light_grey);

    color: var(--blue);
    font-weight: 600;
    font-size: 18px;
  }

  .clear_button {
    padding: 12px 16px;

    border-radius: 100px;

    color: var(--white);
    background-color: var(--orange);

    font-weight: 600;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

.no_search_yielded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 60px; //Figma shows 39.01px

  text-transform: uppercase;
  color: var(--medium_grey);

  > span {
    color: var(--dark_grey);
  }
}
