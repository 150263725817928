@use '../mixins';

.action_btns__container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  justify-content: start;

  button {
    width: 26px;
    height: 25px;
    padding: 5px;
    border-radius: 100%;
    background-color: var(--light_blue);

    &:hover:enabled {
      opacity: 0.9;
    }

    &:disabled {
      pointer-events: none;
      background-color: var(--light_grey);
    }
  }

  .loader {
    @include mixins.submit_loader(14px, $border-width: 3px, $color: #ffffff);
    margin: -2px;
  }
}

.expandable {
  .status_container {
    padding: 0 18px;
  }
}

.order_date_container {
  display: block;
  max-width: 10ch;

  text-align: center;
  letter-spacing: 1px;
}
