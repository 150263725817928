@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

#notify_container {
  overflow: hidden;
  position: fixed;
  top: 45%;
  right: 0;

  .notification {
    position: relative;
    overflow: hidden;
    transition: transform 300ms ease-in-out;
    border-radius: 8px 0 0 8px;
  }

  .slideIn {
    animation: slideIn 300ms ease-in-out;
  }

  .slideOut {
    transform: translateX(150%);
  }

  .close_btn {
    position: absolute;
    top: 1em;
    right: 5px;
  }
}
