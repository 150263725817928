footer {
  &.terms-and-conditions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 21px;
    min-height: 44px;
    font-weight: 500;
    font-size: 14px;

    color: #6c7783;
    background: var(--ice_blue);
    border-radius: 8px;

    > a {
      color: var(--blue);
      text-decoration: underline;
    }
  }
}
