@use '../mixins';

.alerts_content {
  h4 {
    margin-bottom: 12px;
    color: var(--blue);
    font-size: 18px;
    letter-spacing: 1px;
  }

  .menu_section__container {
    display: inline-block;
  }

  .weekdays_submenu {
    padding: 0 24px;

    .menu_section__container {
      display: grid;
      grid-template-columns: repeat(3, auto);
      max-width: 30em;

      .radio_btn__row {
        font-size: 14px;
      }
    }
  }

  .submit_btn {
    display: block;
    @include mixins.default_button(16px, 20px);

    max-width: 270px;
    margin: 24px 0;
    padding: 16px 40px;
    letter-spacing: 0.02em;
  }
  .submit_btn.loader {
    min-width: 214px;
    padding: 15px;

    & > div {
      @include mixins.submit_loader(16px);
    }
  }

  .notifications_loading {
    .loading {
      @include mixins.loading_placeholder();

      height: 100px;
      max-width: 400px;
    }
  }

  .error_msg {
    color: var(--status_error);
    font-weight: bold;
    font-size: 14px;
  }
}
