.alert_window {
  padding: 26px 50px 36px;
  background-color: var(--light_blue);

  margin: -1px;
  border-radius: 8px;

  .icon {
    width: 50px;
    height: 50px;

    margin: 0 auto 20px;

    background-color: var(--white);
    border-radius: 100px;
  }

  .message {
    max-width: 30ch;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--white);
  }
}
