@use '../mixins';

.create_acc--form {
  &.loading {
    & > .loading {
      @include mixins.loading_placeholder;
      width: 500px;
      height: 200px;
      margin-bottom: 30px;
    }
  }

  .error {
    max-width: 503px;
    color: var(--status_error);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;
  }

  & > h3 {
    margin: 24px 0 12px;
    font-weight: 600;
    font-size: 16px;
    color: var(--blue);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
  }

  .form_input {
    width: 275px;
    margin-bottom: 35px;
  }

  .pwd_input {
    width: 420px;
  }

  .submit_btn {
    margin: 30px 0;

    &.loader {
      width: 258px;
    }
  }

  .divider {
    max-width: 630px;
    border-bottom: 1px solid var(--light_grey);
  }
}
