@use '../mixins';

.confirm_restore {
  & > p {
    color: var(--dark_grey);
  }

  & > .alert_upload__cancel {
    min-width: 150px;
    margin: 12px auto;

    & > .loader {
      @include mixins.submit_loader(14px, 3px);
    }
  }

  & > .alert_upload__confirm {
    color: var(--blue);
    padding: 0 20px;
    margin: 8px;
  }
}

.doc_rep_content {
  & > .searchbar {
    margin: 0 1rem;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;

    & > * {
      margin: 0;
    }

    & > .search-bar__container {
      width: auto;

      .search-bar {
        width: 320px;
      }
    }
  }
}
