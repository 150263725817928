.update_profile--form {
  .error {
    max-width: 503px;
    color: var(--status_error);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;
  }

  & > h4 {
    margin-bottom: 12px;
    letter-spacing: 1px;
    font-size: 18px;
    color: var(--blue);
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
  }

  .form_input {
    width: 275px;
    margin-bottom: 35px;
  }

  .submit_btn {
    margin-top: 30px;
    min-width: 90px;
  }

  .divider {
    max-width: 630px;
    border-bottom: 1px solid var(--ice_blue);
  }
}
