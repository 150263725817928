@use '../mixins';

$font_size: 24px;

.not_found {
  min-height: calc(100vh - 250px);

  padding: 24px;
  text-align: center;

  background-image: url('/assets/images/mountains_bg.png');
  background-size: cover;

  & > h1 {
    font-size: 84px;
    line-height: $font_size;
    color: #000000;
  }

  & > h3 {
    color: var(--light_blue);
    font-size: $font_size;
    line-height: 21px;
    letter-spacing: 0.4em;
  }

  & > p {
    max-width: 40ch;
    margin: 0 auto;

    color: var(--dark_grey);
    font-weight: 500;
    font-size: $font_size;
    line-height: 31px;
    letter-spacing: 0.02em;
  }

  & > button {
    @include mixins.default_button(14px, 16px);

    border: 4px solid #ffffff;

    margin-top: 20px;
    padding: 16px 40px;
  }
}
