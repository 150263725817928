.radio_btn__row {
  margin-bottom: 10px;
  font-size: 16px;

  & > label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
  }

  .radio {
    height: 22px;

    input[type='radio'] {
      display: none;

      &:checked + span {
        border-color: var(--blue);

        &::after {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          background-color: var(--blue);
        }
      }

      &:disabled + span {
        cursor: not-allowed;
        border-color: var(--light_grey);
        &::after {
          background-color: var(--light_grey);
        }
      }
    }

    span {
      display: inline-block;
      position: relative;

      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 2px solid #939bae;
    }
  }

  .radio_btn__label {
    margin-left: 8px;
  }
}
.radio_btn__row.disabled {
  & > label {
    cursor: not-allowed;
  }
}
