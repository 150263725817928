@use 'notifications';
@use 'read-more';

.bell_icon__container {
  position: relative;

  .qty__container {
    pointer-events: none;

    position: absolute;
    bottom: 4px;
    right: -12px;

    width: 24px;

    background-color: var(--status_error);
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--white);

    color: var(--white);
    text-align: center;
    font-weight: 800;
    font-size: 10px;
  }
}
