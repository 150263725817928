@use './table';
@use '../mixins';

.product_catalog {
  min-height: calc(100vh - 205px);
}

.prod_catalog__container {
  display: grid;
  padding: 30px;

  .prod_catalog__filtering {
    display: grid;
    grid-template-columns: minmax(200px, 61%) 1fr;

    margin-bottom: 30px;

    align-items: start;
  }

  .product_name__container {
    padding: 0 !important;
    max-width: 25vw;
  }

  .price__container {
    text-align: right;
  }

  .no_search_yielded {
    // Arbitrary value
    min-height: calc(100vh - 500px);
  }
}

.prod_catalog__table {
  @include table.prod_catalog__table;

  .loading {
    @include mixins.loading_placeholder;
    height: 24px;
  }
}

.product_cell {
  display: flex;
  align-items: center;

  & > .product_img {
    position: relative;
    width: 80px;
    height: 80px;

    &:hover {
      & > .preview_icon {
        opacity: 1;
      }
    }

    & > .smooth_image_wrapper {
      width: 80px;
      height: 80px;
    }

    & > .preview_icon {
      display: grid;
      place-content: center;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(0, 0, 0, 0.8);
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    .preview_img {
      border: 2px solid var(--dark_grey);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

      width: 300px;
      height: 300px;

      & > .smooth_image_wrapper {
        width: 100%;
        height: 100%;
      }

      & > button {
        position: absolute;
        top: 8px;
        right: 8px;

        padding: 0;

        & > svg {
          padding: 4px;
          background-color: var(--ice_blue);
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          border-radius: 100px;
        }
      }
    }
  }

  & > .product_name {
    margin-left: 4px;
    text-align: left;
    color: var(--light_blue);
    font-weight: 600;
    font-size: 14px;
    text-decoration-line: underline;
  }
}
.product_cell.loading__container {
  .loading {
    &:first-child {
      width: 80px;
      height: 80px;
    }

    &:last-child {
      flex: 1;
      min-width: 10vw;
      margin: 0 8px;
    }
  }
}

.smooth_image_wrapper {
  position: relative;

  .smooth-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    transition: opacity 1s;
  }
  .smooth-image.visible {
    opacity: 1;
  }
  .smooth-image.hidden {
    opacity: 0;
  }
}

.product_cell__loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .loading {
    width: 100%;
    height: 100%;
  }
}

.complete_percentage {
  display: flex;
  align-items: center;

  & > span {
    font-weight: 600;
    margin-right: 6px;
  }

  .complete_bar__container {
    background-color: var(--light_grey);
    width: 100%;
    max-width: 120px;
    height: 6px;
    border-radius: 8px;

    .complete_bar {
      height: 100%;
      border-radius: inherit;
      background-color: white;
    }
  }
}

.header_info__container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.info_icon__container {
  .info_icon {
    display: block;
    margin-left: 6px;

    min-width: 18px;
    height: 18px;

    color: var(--white);
    background-color: var(--light_blue);

    border-radius: 100px;
    text-align: center;

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .description {
    max-width: 180px;
    padding: 8px 12px;

    background: #e6f6fe;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 3px;

    font-size: 11px;
    font-weight: 400;
  }
}
