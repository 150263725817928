@use '../mixins';
@use '../product-catalog/table';

.account_mgmt {
  .account_info {
    margin: 24px 0;
  }

  h4 {
    font-size: 22px;
    text-transform: uppercase;
    color: #006298;
  }

  .tabs_wrapper {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .invite_btn {
      @include mixins.default_button(12px, unset);

      position: relative;
      bottom: 8px;

      padding: 11px 16px;
    }
  }

  .tabs_container {
    & > .tab {
      box-shadow: none;
      border: 1px solid var(--medium_grey);
      border-bottom: none;
    }
  }

  .acc_mgmt_space {
    padding-top: 20px;
    border-top: 1px solid var(--medium_grey);
  }

  .acc_mgmt__table {
    margin-top: 24px;

    display: grid;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    table {
      @include table.prod_catalog__table;

      .loading {
        @include mixins.loading_placeholder;
        height: 20px;
      }
    }

    .email_container {
      cursor: default;
      font-weight: 600;
      color: var(--light_blue);
    }

    .visits_container {
      max-width: 90px;
      text-align: center;
    }

    .active_users_actions_container {
      display: flex;
      flex-direction: column;

      & > button {
        text-align: left;
        color: var(--light_blue);
        text-decoration: underline;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        &:hover {
          opacity: 0.9;
          text-decoration: none;
        }
      }
    }
  }
}
