@use '../mixins';

.submit_btn {
  padding: 0 24px;

  @include mixins.default_button(16px, 48px);

  &.loader {
    padding: 12px;

    & > div {
      @include mixins.submit_loader(16px);
    }
  }
}
