.invite_users__container {
  padding: 16px 20px;
  padding-top: 0;
  max-height: calc(100vh - 80px);
  overflow: auto;

  .error {
    max-width: 50ch;
    margin: 0;
    margin-top: 16px;
    margin-left: 12px;
    font-weight: 600;
    font-size: 14px;
    color: var(--status_error);
  }

  .add_user {
    display: flex;
    align-items: center;

    margin: 0 auto;

    font-weight: 500;
    color: var(--blue);

    &:hover {
      opacity: 0.9;

      & > .label {
        text-decoration: none;
      }
    }

    & > .plus_sign {
      font-size: 16px;
      line-height: 20px;
      margin-right: 6px;
    }

    & > .label {
      font-size: 12px;
      line-height: 15px;
      text-decoration: underline;
    }
  }

  .request_more_licences {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--status_error);

    & > span {
      margin-right: 6px;
      text-decoration-line: underline;
    }
  }

  .submit_btn {
    display: block;
    margin: 24px auto 16px;

    min-width: 216px;

    &.loader {
      padding: 13px 0;
    }
  }

  .cancel_btn {
    margin: 6px auto;
  }

  .remaining_licences {
    float: right;
    margin: 0;
    font-weight: 500;
    font-size: 11px;
    color: var(--medium_grey);

    &.warning {
      font-weight: 600;
      color: var(--status_error);
    }
  }

  .invite_user_form_wrapper {
    padding: 20px 0 24px;
    border-bottom: 1px solid var(--medium_grey);
  }

  .user_container {
    padding: 26px 22px 19px;

    background-color: #f9f9f9;
    border-radius: 8px;
  }

  .email_input {
    width: 100%;
  }

  .select_role_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .role_label {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--dark_grey);
  }

  .invitation_message {
    resize: vertical;

    width: calc(100% - 22px);
    min-height: 115px;

    background-color: #e6f6fe;
    color: var(--dark_grey);

    border: none;
    border-radius: 8px;

    padding: 12px;
    margin-top: 4px;
    margin-bottom: 12px;

    font-family: inherit;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    &::placeholder {
      font-weight: 400;
      color: var(--medium_grey);
    }

    &:focus {
      outline: none;
    }
  }
}
