.form_input_label {
  display: block;
  margin: 0;
  margin-bottom: 3px;
  color: var(--dark_grey);
  font-weight: 500;
  font-size: 12px;

  & > .asterix {
    color: var(--status_error);
    font-weight: bold;
  }

  & > .form_input_error {
    margin: 0;
    font-size: 12px;
    color: var(--status_error);
  }
}

.form_input {
  padding: 12px 16px;

  background-color: #e6f6fe;
  color: var(--dark_grey);

  border-radius: 8px;
  border: none;

  font-family: inherit;
  font-size: 14px;

  &.invalid {
    border: 2px solid var(--status_error);
  }

  &:focus {
    outline: none;
  }
}

.field__row {
  display: flex;
  max-width: 32em;
  align-items: center;

  margin-top: 4px;
  margin-bottom: 32px;

  .pwd_input,
  .email_input {
    display: grid;
    grid-template-columns: 1fr 30px;

    background-color: #e6f6fe;

    border-radius: 8px;

    &.invalid {
      border: 2px solid var(--status_error);
    }

    & > input {
      flex-grow: 2;

      padding: 12px;

      background: inherit;
      color: var(--dark_grey);

      border: none;
      border-radius: 8px;

      font-family: inherit;
      font-size: 14px;

      &:focus {
        outline: none;
      }
    }

    & > .toggle_visibility {
      padding-top: 5px;
      border-radius: 0px 8px 8px 0px;

      &:hover {
        background-color: #e6f6fe;

        & > svg {
          fill: var(--blue);
        }
      }
    }

    & > .remove_email {
      padding-top: 4px;
      border-radius: 0px 8px 8px 0px;

      svg {
        width: 8px;
        height: 8px;
        padding: 4px;
        background-color: rgba(255, 255, 255, 0.75);
        border-radius: 100px;
      }
    }
  }
}
