$tooltip-text-color: #4b515d;
$tooltip-background-color: #f3f5f8;
$tooltip-margin: 30px;
$tooltip-arrow-size: 6px;

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.tooltip-tip {
  z-index: 1;
  position: absolute;
  left: 7px;
  transform: translateX(-50%);
  padding: 0 2px;
  border-radius: 4px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    height: 0;
    width: 0;
    pointer-events: none;
    border: solid transparent;
    border-width: $tooltip-arrow-size;
  }
}

.tooltip-tip.top {
  top: -24px;

  &::before {
    top: 100%;
    border-top-color: $tooltip-background-color;
  }
}
