@use '../mixins';

.upload_file {
  padding: 16px 32px;

  & > .add_btn {
    display: flex;
    align-items: center;
    grid-gap: 4px;

    cursor: pointer;
    font-weight: bold;
    color: var(--light_blue);

    & > span {
      font-size: 14px;
      font-weight: 500;
      text-decoration-line: underline;
    }

    & > input[type='file'] {
      display: none;
    }
  }

  & > .boxes {
    padding: 24px 0;
    display: flex;

    & > .drag_n_drop {
      min-width: 300px;
      width: 100%;
      height: 300px;
      margin-right: 30px;
      background: #f9f9f9;
      border: 1px dashed var(--dark_grey);
      border-radius: 8px;
      box-sizing: border-box;

      & > p {
        margin: 0;
        padding: 16px;
        font-size: 13px;
        color: #656565;
      }
      & > div {
        text-align: center;
      }
    }

    & > .drag_n_drop.dragging {
      border: 3px dashed var(--light_blue);
      & > div {
        & > svg {
          fill: var(--light_blue);
        }
      }
    }
    & > .files {
      min-width: 400px;
      width: 100%;
      height: 300px;
      border: 1px solid #939bae;
      display: flex;
      flex-direction: column;

      & > .no-files {
        height: 100%;
        display: grid;
        place-items: center;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #cad1e1;
      }

      & > ul {
        list-style: none;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
        margin: 0;

        & > li {
          display: grid;
          grid-template-columns: 30px 1fr auto;
          padding: 10px;
          color: var(--dark_grey);

          &:nth-child(odd) {
            background: #f9f9f9;
          }

          & > svg {
            padding-top: 5px;
          }

          & > .file_name {
            @include mixins.text_trimming;

            margin: 0;
            width: 95%;
          }

          & > .error {
            @include mixins.error_icon();
          }

          & > .loader {
            position: relative;
            background: var(--light_blue);
            height: 22px;
            width: 22px;
            border-radius: 50%;
            background: conic-gradient(transparent, #03abe3);
            animation: rotate 5s linear infinite;

            &::before,
            &::after {
              content: '';
              position: absolute;
              border-radius: 50%;
            }

            &::before {
              width: 16px;
              height: 16px;
              top: 3px;
              left: 3px;
              background: var(--white);
            }
          }

          @keyframes rotate {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }

      .doc_types {
        &__toggler {
          display: flex;
          width: 130px;
          color: var(--blue);
          background: var(--white);
          border: 1px solid var(--blue);
          box-sizing: border-box;
          padding: 0 8px;
          border-radius: 3px;
          text-align: left;
          font-weight: 600;
          font-size: 11px;
          line-height: 20px;

          & > .type_text {
            @include mixins.text_trimming;
            flex: 1;
          }

          & > svg {
            transform: rotate(90deg);
            float: right;
            padding-left: 14px;
          }
        }

        & > #popper {
          z-index: 1;
        }

        &__content {
          display: flex;
          flex-direction: column;
          max-height: 230px;
          width: 250px;
          overflow-y: auto;
          background: var(--white);
          position: absolute;
          right: 0;
          border: 1px solid var(--blue);
          border-radius: 3px;
          box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.25);

          & > .doc_types__type {
            padding: 0 8px;
            color: var(--blue);
            text-align: left;
            font-weight: 500;
            font-size: 11px;
            line-height: 20px;

            &:hover {
              background: var(--ice_blue);
            }
          }
        }
      }
    }
  }

  & > .footer {
    display: flex;
    justify-content: flex-end;

    & > .error {
      flex: 1;
      padding: 12px 0;
      font-size: 13px;

      & > .error__icon {
        @include mixins.error_icon;
        padding-left: 0;
      }
    }

    & > button {
      @include mixins.default_button;

      padding: 0 24px;
      letter-spacing: 0.04em;
    }
  }
}

.alert_upload {
  $red: #d81d1d;

  padding: 0 16px 16px;
  max-width: 350px;
  text-align: center;

  & > h2 {
    margin-top: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: $red;
  }

  & > p {
    margin: 0 auto;
    max-width: 25ch;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $red;
  }

  .highlighted {
    font-weight: bold;
  }

  &__cancel {
    display: block;
    margin: 0 auto;
    margin-top: 20px;

    @include mixins.default_button(14px, 12px);
    padding: 12px;
    letter-spacing: 0.02em;
  }

  &__confirm {
    color: $red;
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;

    @include mixins.text_link_btn;
  }
}
