@use '../mixins';

.confirm_window__container {
  min-width: 350px;

  & > .header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    padding: 16px 24px;
    margin: -1px;
    background-color: var(--light_blue);
    border-radius: 8px 8px 0px 0px;

    h1 {
      margin: 0;
      color: var(--white);
      font-weight: 600;
      font-size: 18px;
    }
  }

  .content {
    padding: 24px;
    text-align: center;

    $success: #40994e;

    & > svg {
      border-radius: 100px;
      background-color: $success;
      padding: 8px 7px;
    }

    & > h1 {
      margin: 0;
      color: $success;
      font-weight: bold;
      font-size: 24px;
    }

    & > p {
      max-width: 35ch;
      color: var(--grey);
      font-weight: 500;
      font-size: 14px;
    }

    & > .iterate {
      display: block;
      margin: 20px auto;

      @include mixins.default_button(14px, 12px);
      padding: 12px;
      letter-spacing: 0.02em;
    }

    & > .close {
      color: var(--blue);
      font-weight: 600;
      font-size: 14px;

      @include mixins.text_link_btn;
    }
  }
}
