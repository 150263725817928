.datepicker_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 8px;
  border-radius: 8px 8px 0 0;

  background-color: var(--light_blue);

  & > .change_month {
    padding: 4px 5px 2px 5px;
    border-radius: 100%;
    background-color: var(--white);

    &.prev_page {
      transform: rotate(180deg);
    }
  }

  & > .current_date_info {
    color: var(--white);
    font-weight: 600;

    & > .year {
      font-size: 12px;
    }
    & > .date {
      font-size: 20px;
    }
  }
}

.datepicker_input {
  display: inline-flex;

  background-color: #e6f6fe;

  border-radius: 8px;

  & > input {
    max-width: 140px;
    padding: 12px;

    background: inherit;
    color: var(--dark_grey);

    border: none;
    border-radius: 8px;

    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;

    &:focus {
      outline: none;
    }
  }

  & > button {
    padding: 0 12px;
    border-left: 1px solid var(--white);
    border-radius: 0 8px 8px 0;
  }
}

.react-datepicker {
  background: var(--white);
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  .react-datepicker__header {
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;

    .react-datepicker__day-name {
      font-weight: bold;
      font-size: 12px;
      line-height: 22px;
      color: var(--blue);
    }
  }

  .react-datepicker__month {
    padding: 0 8px;
    border-radius: 0 0 8px 8px;
    background-color: var(--white);
  }

  .react-datepicker__day {
    position: relative;
    border-radius: 100px;
    cursor: pointer;

    &:hover {
      background-color: var(--ice_blue);
    }

    &,
    &-name {
      display: inline-block;
      width: 2rem;

      margin: 4px;
      padding: 4px 0;

      color: var(--light_blue);
      text-align: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
    }

    &--today {
      font-weight: bold;
    }

    &--selected,
    &--range-start,
    &--range-end {
      background-color: var(--light_blue);
      color: var(--white);

      &:hover {
        background-color: var(--blue);
      }
    }

    &--disabled {
      cursor: default;
      color: var(--medium_grey);

      &:hover {
        background: transparent;
      }
    }
  }
}

.react-datepicker__week {
  position: relative;
  z-index: 1;

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    &:not(.react-datepicker__day--range-start, .react-datepicker__day--range-end) {
      background-color: #e6f6fe;
    }
  }
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  & + & {
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      border-radius: 0 100px 100px 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 57px;
      background-color: #e6f6fe;
    }
  }
}

.react-datepicker-popper {
  &[data-placement^='top'],
  &[data-placement^='bottom'] {
    .react-datepicker__triangle {
      &::before,
      &::after {
        box-sizing: content-box;
        position: absolute;
        border: 8px solid transparent;
        height: 0;
        width: 1px;
        content: '';
        z-index: -1;
        border-width: 8px;
        left: 32px;

        border-top: none;
        border-bottom-color: var(--light_blue);
      }
    }
  }

  &[data-placement^='bottom'] {
    padding-top: 3px;

    .react-datepicker__triangle {
      top: 0;
      margin-top: -4px;
    }
  }
}
