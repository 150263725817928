@use '../mixins';

@mixin flex_align_center {
  display: flex;
  align-items: center;
}

@mixin text_format($color, $size: 16px, $weight: 600) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
}

.upload_product_docs {
  max-width: 50vw;

  & > .header {
    @include flex_align_center;

    margin: -1px;
    padding: 12px 20px;
    background-color: var(--light_blue);
    border-radius: 8px 8px 0px 0px;

    h1 {
      flex: 1;
      margin: 0;
      margin-left: 8px;

      @include text_format(var(--white), 18px);
    }

    button {
      display: inline-flex;
    }
  }

  & > .content {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1em;
    min-width: 650px;

    padding: 20px;

    .footer_text {
      @include text_format(var(--medium_grey), 12px, 500);
      margin: 0;
      text-align: center;
    }

    .submit_btn {
      display: block;
      margin: 0 auto;

      padding: 12px;
      @include mixins.default_button(12px, 14px);
    }
  }

  .step__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    & > .step_title,
    & > .step__content {
      grid-row: 1;
    }

    & > .step_title {
      grid-column: 1 / 1;
      z-index: 1;

      @include flex_align_center;

      & > .step {
        min-width: 35px;

        background-color: var(--orange);

        padding: 2px;
        border-radius: 100px;
        border: 3px solid var(--white);

        @include text_format(var(--white), 28px, bold);
        text-align: center;
      }

      & > .description {
        margin-left: 4px;

        & > p {
          margin: 0;

          @include text_format(var(--orange), 12px);
        }

        & > h2 {
          max-width: 10ch;
          margin: 0;

          @include text_format(var(--orange), 18px, 800);
          text-transform: uppercase;
        }
      }
    }

    & > .step__content {
      grid-column: 1 / -1;

      @include flex_align_center;

      padding: 20px;
      padding-left: 150px;
      margin-left: 23px;
      background-color: var(--ice_blue);
      border-radius: 8px;

      & > .dropdown_btn {
        margin-right: 30px;

        & > button {
          min-width: 180px;
          max-width: 300px;
          white-space: nowrap;

          & > .name {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .menu__container {
          max-height: 250px;
          overflow: auto;
        }

        .file_label_menu__container {
          display: inline-block;

          .radio_btn__label {
            color: var(--dark_grey);
          }
        }
      }

      .uploaded_file {
        .loader {
          @include mixins.gradient_loader(30px, 4px, var(--ice_blue));
        }
      }
    }
    .step__content.file_upload {
      padding-left: 0;
      display: block;

      & > .upload_btns {
        @include flex_align_center;
        padding-left: 150px;

        .drag_n_drop {
          @include flex_align_center;
          padding: 12px;
          border-radius: 8px;

          background-color: var(--white);
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23006298' stroke-width='2' stroke-dasharray='11' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");

          @include text_format(var(--blue), 12px);

          & > svg {
            margin-right: 8px;
            fill: var(--blue);
          }

          & > p {
            margin: 0;
          }
        }

        .drag_n_drop.dragging {
          color: var(--light_blue);
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2303abe3' stroke-width='2' stroke-dasharray='11' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");

          & > svg {
            fill: var(--light_blue);
          }
        }

        .drag_n_drop.disabled {
          color: var(--light_grey);
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23bcc4d8' stroke-width='2' stroke-dasharray='11' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
          cursor: not-allowed;

          & > svg {
            fill: var(--light_grey);
          }
        }

        & > h3 {
          position: relative;
          margin: 0 40px;

          @include text_format(var(--medium_grey), 16px);

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            width: 20px;
            height: 1px;
            background-color: var(--medium_grey);
          }

          &::before {
            left: -25px;
          }

          &::after {
            right: -25px;
          }
        }

        & > input[type='file'] {
          display: none;
        }

        & > .add_file {
          background-color: var(--white);
          padding: 12px 16px;

          box-sizing: border-box;
          border: 1px solid var(--blue);
          border-radius: 100px;

          @include text_format(var(--blue), 12px);

          letter-spacing: 0.02em;
          text-transform: uppercase;

          &:hover {
            color: var(--light_blue);
            border: 1px solid var(--light_blue);
          }

          &:disabled {
            color: var(--light_grey);
            border: 1px solid var(--light_grey);
            cursor: not-allowed;
          }
        }
      }

      & > .error {
        position: relative;
        top: 12px;

        padding-left: 15px;

        @include text_format(var(--dark_grey), 11px, 500);

        & > .error__icon {
          @include mixins.error_icon;
        }
      }
    }
  }
}

.modal_loading__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(white, 0.8);
  z-index: 999;

  display: grid;
  place-content: center;

  & > div {
    text-align: center;

    & > h1 {
      max-width: 16ch;
      font-weight: bold;
      font-size: 24px;
      color: var(--dark_grey);
    }

    & > .loader {
      margin: 16px auto 32px;
      @include mixins.gradient_loader(68px, 10px);
    }

    & > .divider {
      margin-bottom: 16px;
      border-bottom: 1px solid var(--medium_grey);
    }

    & > .cancel {
      @include mixins.link_text(14px, 500, 17px);
      @include mixins.text_link_btn;
      color: var(--status_error);
    }
  }
}
