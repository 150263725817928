@use '../mixins';

.edit_role_container {
  padding: 26px 30px;

  h6 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 16px;
    color: var(--blue);
  }

  .submit_btn {
    display: block;
    min-width: 178px;
    margin: 30px auto 12px;
  }

  .cancel_btn {
    margin: 16px auto;
  }

  .loading {
    @include mixins.loading_placeholder;
    height: 80px;
    width: 490px;
  }
}
