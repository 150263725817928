@use '../mixins';
@use '../modal';

@mixin input {
  margin-top: 4px;
  padding: 12px;
  width: calc(100% - 24px);

  background: #d0ebf9;
  color: var(--dark_grey);

  border: none;
  border-radius: 8px;

  font-family: inherit;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;

  &:focus {
    outline: none;
    border: 1px solid var(--light_grey);
  }
}

.view_all__btn,
.gxp_sku__link {
  color: var(--blue);
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-left: 4px;
    }
  }
}

.associated_sku {
  & > .content {
    padding: 12px 24px;

    table {
      width: 100%;
      padding: 0;

      tr {
        box-shadow: none;
      }

      th {
        font-weight: bold;
        padding: 0 18px 8px;
      }

      td {
        padding: 4px 18px;
      }
    }

    .pagination {
      padding-top: 12px;
      border-top: 1px solid var(--light_grey);
    }
  }

  .gxp_sku__link {
    & > a {
      justify-content: left;
    }
  }

  .prod_name_container {
    max-width: 400px;

    & > .prod_name {
      @include mixins.text_trimming;
    }
  }
}
