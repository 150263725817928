@use './table';
@use '../mixins';

.product_purchases {
  display: grid;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  .order_date_container {
    max-width: 100%;
    text-align: left;
  }

  .purchase_name__link {
    display: inline-flex;
    align-items: center;

    @include mixins.link_text(14px, 600, 0);
    color: var(--light_blue);

    & > svg {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      stroke: var(--light_blue);
    }
  }

  table {
    @include table.prod_catalog__table;

    .loading {
      @include mixins.loading_placeholder;
      height: 19px;
    }

    .table_row_select {
      @include mixins.table_row_select;
    }

    .row_select__container {
      width: 16px;
      padding: 0 12px;
    }
  }

  .expandable {
    .doc_type_container {
      color: var(--light_blue);
      font-weight: 600;
      font-size: 14px;
      text-decoration-line: underline;

      .expander {
        display: flex;
        grid-gap: 8px;
        align-items: center;

        & > div {
          position: relative;
          width: 18px;
          height: 4px;
          border-radius: 8px;
          background-color: var(--light_blue);
        }

        & > div.open {
          &::after {
            position: absolute;
            top: -7px;
            left: 7px;
            content: '';
            background-color: inherit;
            width: 4px;
            height: 18px;
            border-radius: 8px;
          }
        }
      }
    }
  }
  .expandable.active {
    background-color: #e6f6fe;
  }

  .sub-component {
    background-color: var(--white) !important;

    & > td {
      padding: 0 0 12px 24px !important;
    }

    .sub-row {
      background-color: #e6f6fe;
      border: 1px solid var(--light_blue);
      border-right: none;
      box-sizing: border-box;
      border-radius: 0px 0px 0px 8px;

      & > h3 {
        padding: 10px 20px 20px;
        color: var(--blue);
        margin: 0;
        font-weight: 600;
        font-size: 16px;
      }

      table {
        max-width: 65em;
        border: none;

        thead {
          background-color: #e6f6fe;
          border-bottom: 1px solid var(--white);

          th {
            color: var(--blue);
            border: none;
            font-weight: 600;
            font-size: 14px;
          }
        }

        tbody {
          tr {
            background-color: #e6f6fe;

            &:last-child {
              td {
                &:last-child {
                  border-bottom-right-radius: 0;
                }
              }
            }

            td {
              border: 1px solid var(--white);
              border-left: none;
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .doc_name_container {
    max-width: 0;

    & > button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0;
      text-align: left;

      & > .doc_name {
        display: block;

        @include mixins.link_text(16px, 600, 18px);
        @include mixins.text_trimming;

        color: var(--light_blue);
      }

      & > .icon {
        position: relative;
        top: 1px;
        left: 5px;
        stroke: var(--light_blue);
      }
    }
  }
}
