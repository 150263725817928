.confirm_window {
  display: grid;
  place-items: center;

  min-height: 200px;
  padding: 40px;
  margin: -1px;
  background-color: var(--light_blue);

  border-radius: 8px;

  text-align: center;

  & > h1 {
    max-width: 25ch;
    color: var(--white);
    font-weight: bold;
    font-size: 28px;
    letter-spacing: 0.02em;
  }

  & > button {
    margin: 40px 0;
    padding: 4px 24px;

    color: var(--blue);
    background-color: var(--white);

    border: 1px solid var(--blue);
    box-sizing: border-box;
    border-radius: 100px;

    font-weight: 600;
    font-size: 16px;
    line-height: 48px;

    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
}
