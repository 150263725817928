@use '../mixins';

.doc_rep_content {
  h3 {
    margin: 0 1rem;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 1px;
    color: var(--blue);
  }

  p {
    max-width: 70ch;
    margin-top: 0;
    margin-left: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--grey);
  }

  .marked_red {
    font-weight: bold;
    color: var(--status_error);
  }

  .search-bar__container {
    margin: 2rem 1rem;
  }

  .no_search_yielded {
    border: 1px solid #cad1e1;
    border-radius: 9px;
    min-height: calc(100vh - 700px);
  }

  table {
    .doc_name_container {
      max-width: 0;

      & > button {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        text-align: left;

        & > .doc_name {
          display: block;

          @include mixins.link_text(16px, 600, 18px);
          @include mixins.text_trimming;
        }

        & > .icon {
          position: relative;
          top: 1px;
          left: 5px;
          stroke: var(--blue);
        }
      }
    }

    .doc_label_container {
      padding: 15px;

      & > .doc_label {
        max-width: 175px;

        & > .not_applicable {
          font-weight: bold;
          color: var(--status_error);
        }
      }
    }

    .sku_container {
      text-align: center;

      & > .header-cell {
        justify-content: center;
      }
    }

    .actions {
      @include mixins.relatively_centered(165px);
    }

    .remaining {
      @include mixins.relatively_centered(140px);
    }

    .delete {
      @include mixins.relatively_centered(55px);
    }
  }
}

.table_action_btns {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;

  & > .btn {
    padding: 0 1rem;
    border-right: 1px solid lightgrey;
    background-color: var(--white);

    display: flex;
    align-items: center;

    @include mixins.link_text(16px, 600, 18px);

    &:last-child {
      border: 0;
    }

    & > svg {
      padding: 6px;
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--light_grey);
    }

    & > .loading {
      @include mixins.submit_loader(13px, 2px, var(--blue));

      margin-left: 10px;
    }
  }
}
