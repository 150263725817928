.table_row_select {
  & > input[type='checkbox'] {
    display: none;

    & + span {
      display: grid;
      place-items: center;
      vertical-align: middle;
      cursor: pointer;
      background: var(--white);
    }
  }
}
