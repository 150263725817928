@use './mixins';

header {
  display: flex;

  & > * {
    background: #ffffff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  & > .menu-btn {
    margin-top: 16px;
    transform: translateX(0);
    transition: transform 300ms ease-in-out;
  }

  & > .menu-btn.hidden {
    transform: translateX(-100%);
  }

  .nav-bar {
    width: 100%;
    height: 72px;

    margin: 16px 24px 0;
    padding: 0 24px;
    border-radius: 8px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .header {
      display: flex;

      & > .logo {
        height: 40px;
        width: 26.67px;
      }

      & > .vendor-logo {
        height: 40px;
        width: 174px;

        & > img {
          object-fit: contain;
        }
      }
    }

    & > .actions {
      display: flex;

      font-weight: 500;
      font-size: 12px;

      & > .badge {
        background-color: var(--status_error);
        color: var(--white);
        padding: 2px 9px;
        font-weight: bold;
        font-size: 9px;
        line-height: 16px;
        text-transform: uppercase;
        border-radius: 100px;
      }

      & > .user-name {
        color: var(--grey);
      }

      & > a {
        color: var(--light_blue);
      }
    }
  }

  .divider {
    border-left: 1px solid #bebebe;
    margin: 0 16px;
  }

  .loading {
    min-width: 150px;
    @include mixins.loading_placeholder;
  }
}
