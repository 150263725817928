@use '../mixins';

$input_width: 30em;
$submit_font: 16px;

.update_pwd {
  &__content {
    padding: 12px 24px;

    & > h4 {
      color: var(--blue);
      font-size: 18px;
      letter-spacing: 1px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    .pwd_input {
      width: 30em;
    }

    .pin {
      width: 18px;
      height: 18px;
      padding: 4px;
      margin-left: 6px;
      border-radius: 100px;
      background-color: #40994e;
    }
    .pin.invalid {
      background-color: var(--status_error);
    }

    & > .submit_btn {
      @include mixins.default_button($submit_font, $submit_font);

      max-width: 270px;
      margin: 24px 0;
      padding: 16px 40px;
      letter-spacing: 0.02em;
    }
    & > .submit_btn.loader {
      padding: 13px;

      & > div {
        @include mixins.submit_loader($submit_font);
      }
    }

    & > .server_error {
      margin: 0;
      color: var(--status_error);
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.hint_container {
  max-width: 65em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hint {
  justify-self: start;
  align-self: start;
  margin-top: 18px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--ice_blue);
  color: var(--dark_grey);
  font-size: 14px;
  letter-spacing: 0.02em;

  & > p {
    margin: 0;
    font-weight: 600;
  }

  & > ul {
    list-style: disc;
    padding: 0 24px;
    margin-bottom: 0;
  }
}
