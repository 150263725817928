@use '../mixins';

.edit_doc__modal {
  display: grid;
  grid-template-columns: minmax(200px, 20%) 1fr;

  & > .edit_doc__content {
    padding: 24px 12px;
    box-shadow: -5px 0px 6px rgba(0, 0, 0, 0.1);

    .submit_btn {
      display: block;
      margin: 24px auto 12px;

      &.loader {
        padding: 13px;
        min-width: 140px;
      }
    }

    .close_btn {
      display: block;
      margin: 0 auto;

      @include mixins.link_text(14px, 500, 12px);
      @include mixins.text_link_btn();
    }

    & > .upload_file {
      padding: 0;
    }
  }
}

.page_sidebar__container.submenu {
  width: auto;
  min-height: auto;

  & > .sidebar_btn {
    padding: 16px 0 16px 10px;
    margin-top: 8px;
    margin-left: 8px;

    font-size: 13px;
    line-height: 16px;

    & > * {
      fill: var(--blue);
      color: var(--blue);
      opacity: 0.7;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &:hover {
      & > * {
        fill: var(--blue);
        color: var(--blue);
        opacity: 1;
      }
    }
  }

  & > .active {
    & > span,
    & > svg {
      opacity: 1;
    }
  }
}

.edit_doc__name {
  display: flex;
  grid-gap: 1em;

  padding: 24px 12px;
  background-color: var(--ice_blue);
  border-radius: 8px;

  & > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    .form_input {
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.25);
      width: 320px;
    }

    & > .dropdown_btn {
      & > button {
        width: 220px;
        white-space: nowrap;

        & > .name {
          font-size: 12px;
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .menu__container {
        max-height: 400px;
        overflow: auto;
      }
    }
  }
}
