.prod_catalog__settings {
  justify-self: end;

  display: flex;
  flex-wrap: wrap;
  grid-gap: 8px;
}

.hide_columns__container {
  display: flex;
  flex-direction: column;

  .toggle_all {
    display: flex;
    justify-content: space-between;

    margin-top: 12px;

    & > button {
      padding: 0;
      white-space: nowrap;
      color: var(--light_blue);
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;

      &:disabled {
        color: var(--medium_grey);
      }
    }

    .hide {
      color: var(--status_error);
    }
  }
}

.items_per_page__container {
  display: inline-block;
}

.category_filter__container {
  padding: 0 !important;

  .filter_toggler {
    font-weight: bold;
  }
}

.category_row {
  margin-bottom: 10px;

  & > .category_checkbox {
    display: flex;
    align-items: center;

    cursor: pointer;

    &:hover {
      .checkbox {
        span {
          border: 2px solid var(--blue);
        }
      }

      .category_name {
        font-weight: bold;
        font-size: 16px;
        color: var(--blue);
      }
    }

    & > .checkbox {
      input[type='checkbox'] {
        display: none;

        &:checked + span {
          background-color: var(--light_blue);
          border-color: var(--light_blue);
        }
      }

      span {
        display: inline-grid;
        place-items: center;

        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: var(--white);
        border: 2px solid #939bae;
      }
    }

    & > .category_name {
      margin-left: 8px;

      font-weight: 500;
      font-size: 16px;
      color: var(--blue);
    }
  }
}
