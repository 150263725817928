$section_padding: 36px;

.service_plans_container {
  padding: 40px;
  box-shadow: -5px 0px 6px rgb(0 0 0 / 10%);
}

.service_plan_details {
  padding-bottom: $section_padding;
  border-bottom: 1px solid var(--light_grey);

  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: var(--blue);
  }

  .current_plan_container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 50px;
    justify-content: center;

    margin-top: 14px;

    font-weight: 500;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: var(--dark_grey);

    .highlighted {
      display: block;
      font-weight: 700;
      color: var(--orange);
    }
  }
}

.plans_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: $section_padding;
}

.available_plans {
  padding: $section_padding 0;
  border-bottom: 1px solid var(--light_grey);

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--grey);
  }
}

.plan_column_container {
  flex: 1;
  max-width: 340px;

  position: relative;

  background-color: var(--white);
  background-size: cover;
  padding: 50px 40px;

  border: 1px solid var(--light_grey);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  &::before {
    position: absolute;
    content: '';
    background-color: var(--light_blue);
    left: 0;
    top: -1px;
    width: 100%;
    height: 18px;
    border-radius: 12px 12px 0px 0px;
  }

  &:nth-child(1) {
    padding: 20px 40px;
    background-image: url('/assets/images/Mountains-Compressed 2.png');
  }

  &:nth-child(2) {
    z-index: 1;
    transform: scaleX(1.1);

    background-image: url('/assets/images/Mountains-Compressed 1.png');

    & > * {
      transform: scaleX(0.9);
    }

    &::before {
      background-color: var(--orange);
    }

    .request_more_info {
      background-color: var(--orange);
    }
  }

  &:nth-child(3) {
    padding: 20px 40px;
    background-image: url('/assets/images/Mountains-Compressed 3.png');
  }

  & > div {
    padding: 18px 0;

    & + div {
      border-top: 1px solid var(--light_grey);
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--blue);
  }

  .current_plan {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    color: var(--light_blue);
  }

  .request_more_info {
    display: block;
    margin: 0 auto;
    padding: 9px 16px;

    border-radius: 100px;

    color: var(--white);
    background-color: var(--light_blue);

    font-weight: 600;
    font-size: 12px;

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &:hover {
      opacity: 0.9;
    }
  }
}

.feature_section {
  padding: 0 8px;

  & + & {
    margin-top: 16px;
  }

  .section_name {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    color: var(--blue);
  }

  .feature_list {
    margin: 0;

    .feature_row {
      display: flex;
      align-items: center;

      &.disabled {
        .feature_name {
          color: var(--medium_grey);
          text-decoration: line-through;
        }
      }
    }

    .feature_name {
      margin-left: 6px;
      margin-right: 8px;

      color: var(--dark_grey);
      font-weight: 500;
      font-size: 12px;
      line-height: 29px;
    }
  }

  .feature_description__container {
    .description {
      max-width: 180px;
      padding: 8px 12px;

      color: var(--dark_grey);
      background-color: var(--ice_blue);
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

      border-radius: 4px;

      font-size: 10px;
      font-weight: 500;
    }

    #popper {
      &[data-show='true'] {
        z-index: 2; // overflow middle row
      }
    }
  }
}
