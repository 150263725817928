@mixin text_trimming {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin error_icon {
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  padding-left: 3px;
  color: var(--status_error);
}

@mixin default_button($font-size: 16px, $line-height: 48px) {
  cursor: pointer;
  color: var(--white);
  background: var(--light_blue);
  border: 0;
  border-radius: 100px;
  font-family: inherit;
  font-weight: 600;
  font-size: $font-size;
  line-height: $line-height;
  text-transform: uppercase;

  &:hover:enabled {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--light_grey);
    color: var(--dark_grey);
  }
}

@mixin submit_loader($size: 20px, $border-width: 3px, $color: #ffffff) {
  width: $size;
  height: $size;
  margin: 0 auto;
  border: $border-width solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: $border-width solid $color;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin link_text($size, $weight, $height) {
  color: var(--blue);
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  text-decoration-line: underline;
}

@mixin relatively_centered($max-width) {
  display: block;
  text-align: center;
  max-width: $max-width;
}

@mixin loading_placeholder {
  animation: placeholderShimmer 10s linear infinite forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);

  @keyframes placeholderShimmer {
    0% {
      background-position: -30vw 0;
    }
    100% {
      background-position: 70vw 0;
    }
  }
}

@mixin text_link_btn {
  text-decoration-line: underline;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
  }
}

@mixin table_row_select {
  $size: 16px;

  & > input[type='checkbox'] {
    & + span {
      width: $size;
      height: $size;
      box-sizing: border-box;
      border: 2px solid var(--light_blue);
      border-radius: 2px;

      & > svg {
        width: 10px;
        height: 13px;
      }
    }

    &:checked {
      & + span {
        background-color: var(--light_blue);
      }
    }

    &:indeterminate {
      & + span {
        &::before {
          content: '';
          border-radius: 3px;
          width: 8px;
          height: 2px;
          background-color: var(--light_blue);
        }
      }
    }
  }
}

@mixin gradient_loader($size, $border, $bg: var(--white)) {
  position: relative;
  background: var(--light_blue);
  height: $size;
  width: $size;
  border-radius: 50%;
  background: conic-gradient(transparent, #03abe3);
  animation: rotate 5s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &::before {
    width: calc(#{$size} - #{$border} * 2);
    height: calc(#{$size} - #{$border} * 2);
    top: $border;
    left: $border;
    background: $bg;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
