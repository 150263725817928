@use '../mixins';

.add_product_record__container {
  $description_column_width: 55%;

  .searching {
    display: flex;
    margin-bottom: 12px;

    & > label {
      color: var(--dark_grey);
      font-weight: 500;
      font-size: 12px;

      & + label {
        flex: 1;
        padding-left: 8px;
      }

      &:first-child {
        flex-basis: 100%;
        & > input {
          width: 100%;
        }
      }
    }

    input[type='text'] {
      font-family: inherit;
      width: 134px;
      margin-top: 4px;
      padding: 8px 12px;
      border: 1px solid #cad1e1;
      box-sizing: border-box;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      &:focus {
        outline: none;
      }
    }
  }

  table {
    $inset_shadow: inset 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    $border_radius: 4px;

    background-color: #cad1e1;

    width: 100%;
    border-spacing: 0;

    box-sizing: border-box;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: $border_radius;

    td + td,
    th + th {
      border-left: 1px solid #e9e9e9;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      border-radius: 4px 4px 0px 0px;

      th {
        padding: 8px 4px;
        color: var(--dark_grey);
        background-color: rgba(202, 209, 225, 0.9);

        font-weight: 600;
        font-size: 14px;
        text-align: left;

        &:first-child {
          border-top-left-radius: $border_radius;
        }
        &:last-child {
          border-top-right-radius: $border_radius;
        }
      }
    }

    tbody {
      display: block;
      max-height: 70px;
      overflow: auto;

      border-bottom-left-radius: $border_radius;
      border-bottom-right-radius: $border_radius;

      tr {
        background-color: rgba(243, 245, 248, 0.9);

        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.9);
        }

        td {
          padding: 4px;

          font-weight: 500;
          font-size: 12px;
          color: #747474;
        }
      }
    }

    .table_row_select {
      @include mixins.table_row_select;
    }

    .loading {
      @include mixins.loading_placeholder();
      height: 15px;
    }

    .loading_more__row {
      display: flex;
      justify-content: center;

      & > .loader {
        @include mixins.submit_loader(12px);
        margin: 0;
        border: 2px solid #e2e2e2;
        border-top: 2px solid var(--blue);
      }

      & > p {
        margin: 0;
        padding-left: 8px;
      }
    }
  }

  .row_select__container {
    width: 16px;
    padding: 0 12px;
  }
}
