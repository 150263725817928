.filter_by__container {
  .filter_toggler {
    display: flex;
    align-items: center;

    padding: 8px 12px;

    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark_grey);

    white-space: nowrap;

    transition: all 300ms;

    &:hover {
      & > svg {
        fill: var(--light_blue);
      }

      /* Disable on hover */
      & > .disabled {
        fill: var(--white);
        transition: none;
      }
    }

    & > .disabled {
      fill: var(--white);
      transition: none;
    }

    & > svg {
      transition: all 300ms;
      margin-left: 6px;

      fill: var(--white);
      stroke: var(--light_blue);
      stroke-width: 1.5;
    }

    & > .active_filters {
      min-width: 23px;
      margin-left: 8px;
      background-color: var(--light_blue);
      color: var(--white);
      border-radius: 100px;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }
  }
  .filter_toggler.open {
    background-color: var(--light_blue);
    color: var(--white);

    & > svg {
      fill: var(--white);
      stroke: var(--white);
    }

    & > .active_filters {
      background-color: var(--white);
      color: var(--light_blue);
    }
  }
  .filter_toggler.active {
    & > svg {
      fill: var(--light_blue);
    }

    &:hover {
      & > svg {
        fill: var(--white);
      }
    }
  }

  .filter_menu {
    position: absolute;
    z-index: 2; // order docs expandable rows are z-indexed on 1 position

    padding: 20px;
    background: var(--white);

    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border: 1px solid var(--light_blue);
    border-radius: 8px;

    &.category_menu {
      max-width: 320px;
    }

    & > h5 {
      margin: 0;
      color: var(--blue);
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
    }

    & > .clear_all {
      margin-bottom: 16px;
      padding: 0;
      color: var(--status_error);
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;

      &:disabled {
        color: var(--medium_grey);
      }
    }

    & > .divider {
      position: absolute;
      left: 0;
      width: 100%;
      border-bottom: 1px solid #cad1e1;
    }

    & > .filter_categories {
      margin-top: 14px;

      max-height: 300px;
      overflow-x: auto;
    }

    & > .filter_date__container {
      width: 473px;

      & > h6 {
        margin: 18px 0;
        color: var(--blue);
        font-weight: 600;
        font-size: 16px;
      }

      & > .select_filter_method {
        display: flex;

        & > * {
          margin-right: 60px;
        }
      }

      & > .single_date__container {
        display: inline-block;
      }

      & > .date_range__container {
        display: flex;
        align-items: center;

        & > span {
          margin: 0 12px;
        }
      }
    }
  }
}
