@use '../mixins';

.product_documents_tab {
  width: 100%;

  @media screen and (min-width: 1400px) {
    width: 65%;
  }

  .table_action_btns {
    margin: 0;
  }

  & > .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1rem;

    & > .upload {
      @include mixins.default_button(12px, 41px);

      padding: 0 16px;
    }
  }
}

.edit_doc__modal.no_sidebar {
  display: block;
}
