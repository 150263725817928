@use '../mixins';

.product_to_unlink {
  padding: 20px 12px;
  margin-top: 8px;
  background-color: var(--ice_blue);
  border-radius: 8px;
}

.link_action_wrapper {
  display: flex;
  align-items: center;

  .side_line {
    flex: 2;
    margin: 0 24px;
    width: 100%;
    height: 1px;
    background-color: var(--light_grey);
  }

  .link_action_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 12px auto;

    background-color: var(--light_blue);
    color: var(--white);
    padding: 8px;
    border-radius: 100px;

    & > .name {
      padding: 0 12px;
      font-weight: 600;
      font-size: 16px;
      text-decoration-line: underline;
    }

    & > .arrow {
      padding: 0 8px;
      transform: rotate(90deg);
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.selected_products_wrapper {
  padding: 20px 12px;

  & > table {
    $inset_shadow: inset 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    $border_radius: 4px;

    width: 100%;
    border-spacing: 0;

    box-sizing: border-box;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: $border_radius;

    td + td,
    th + th {
      border-left: 1px solid #e9e9e9;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    thead {
      box-shadow: $inset_shadow;
      border-radius: 4px 4px 0px 0px;

      th {
        padding: 8px 4px;
        color: var(--dark_grey);
        background-color: rgba(202, 209, 225, 0.9);

        font-weight: 600;
        font-size: 14px;

        &:first-child {
          border-top-left-radius: $border_radius;
        }
        &:last-child {
          border-top-right-radius: $border_radius;
        }
      }
    }

    tbody {
      display: block;
      height: 120px;
      overflow: auto;
      background-color: rgba(243, 245, 248, 0.9);

      border-bottom-left-radius: $border_radius;
      border-bottom-right-radius: $border_radius;

      tr {
        td {
          padding: 4px;

          font-weight: 500;
          font-size: 12px;
          color: #747474;
        }
      }
    }

    .table_row_select {
      @include mixins.table_row_select;
    }

    .row_select__container {
      width: 16px;
      padding: 0 12px;
    }
  }
}

.product_sku_desc_column {
  width: 55%;

  & > .product_name_link {
    color: var(--light_blue);
    font-weight: 500;
    font-size: 12px;
    text-decoration-line: underline;
  }

  svg {
    width: 11px;
    height: 11px;
    position: relative;
    top: 1px;
    left: 4px;
    stroke: var(--light_blue);
  }

  & > button {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    text-align: left;

    & > .doc_name {
      display: block;

      @include mixins.link_text(12px, 500, 16px);
      @include mixins.text_trimming;

      color: var(--light_blue);
    }
  }
}
