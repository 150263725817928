@use './mixins';

$border-radius: 8px;

.tabs_container {
  display: flex;

  & > .tab {
    position: relative;

    padding: 8px 16px;
    margin: 0 8px;

    color: var(--medium_grey);
    background-color: var(--ice_blue);

    border-radius: $border-radius $border-radius 0px 0px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;

    transition-property: color, background-color;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &:first-of-type {
      margin-left: 23px;
    }

    &:hover {
      color: var(--blue);
      background-color: var(--white);
    }

    &.active {
      color: var(--blue);
      background-color: var(--white);

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        background-color: var(--white);
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 5px;
      }

      .loading {
        @include mixins.loading_placeholder;

        width: 100px;
        height: 18px;
      }
    }
  }
}

.dashboard {
  & > .domo_container {
    position: relative;
    min-height: calc(100vh - 280px);
    background-color: var(--white);
    border-radius: $border-radius;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .loader {
    @include mixins.submit_loader(100px);

    position: relative;
    top: 35vh;

    border: 3px solid #e2e2e2;
    border-top: 3px solid var(--blue);
  }

  .tabs_container {
    margin: 0;

    & > .tab {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .error_load_dashboard {
    position: relative;
    top: 30vh;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #cad1e1;
  }

  iframe {
    width: 100%;
    margin: 0;
    border: none;
    border-radius: $border-radius;
    overflow: hidden;
    height: calc(100vh - 200px);
  }
}
