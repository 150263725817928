@use '../mixins';

.expander_header {
  width: 0;
}

.doc_rep_content {
  & > .header_info {
    display: flex;
    justify-content: space-between;

    & > .download_all {
      margin-right: 1rem;
      display: flex;
      align-items: center;

      @include mixins.link_text(14px, 600, 18px);

      &:hover {
        opacity: 0.9;
      }

      & > #icon {
        margin-left: 5px;
        min-width: 26px;
        height: 25px;
        border-radius: 100%;
        background-color: var(--light_blue);

        & > svg {
          padding-top: 4px;
        }

        .loader {
          @include mixins.submit_loader(12px, $border-width: 2px, $color: #ffffff);
          position: relative;
          top: 4px;
        }
      }
    }
  }
}

.expandable {
  .expander_container {
    position: relative;
    padding: 0 !important;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 6px;
      background-color: var(--blue);
      border-radius: 4px 0px 0px 4px;
    }

    & > .expander {
      height: 73px;
      display: grid;
      align-items: center;
      justify-items: end;

      & > div {
        position: relative;
        width: 18px;
        height: 4px;
        border-radius: 8px;
        background-color: var(--blue);
      }

      & > div.open {
        &::after {
          position: absolute;
          top: -7px;
          left: 7px;
          content: '';
          background-color: inherit;
          width: 4px;
          height: 18px;
          border-radius: 8px;
        }
      }
    }
  }
}

.doc_type_container {
  & > button {
    padding: 0;

    & > .doc_name {
      @include mixins.text_trimming;

      display: block;

      @include mixins.link_text(16px, 600, 36px);
    }
  }
}

.sub-row__header {
  width: 100%;
  border-spacing: 0;
  border-radius: 4px;
}

.sub-row {
  width: 100%;

  tr {
    box-shadow: none !important;
  }

  th {
    padding: 0 20px !important;

    & > .header-cell {
      font-weight: 600;
    }
  }

  td {
    padding: 10px 20px !important;
  }

  .doc_name_container {
    min-width: 200px;
  }

  .sub-row__actions {
    max-width: 60px;
  }

  .sub-row__expiration {
    & > div {
      @include mixins.relatively_centered(140px);
    }
  }

  .sub-row__modified {
    & > div {
      @include mixins.relatively_centered(130px);
    }
  }
}

.mid_size {
  max-width: 70em;
}

.account_info {
  margin: 0 1rem 1rem;
  border-bottom: 1px solid var(--ice_blue);

  & > h4 {
    margin: 0;
    font-size: 24px;
    text-transform: uppercase;
    color: var(--blue);
  }

  &__container {
    display: flex;
    flex-wrap: wrap;

    padding: 24px 0 32px;

    h5 {
      margin: 1em 0;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      color: var(--blue);
    }

    & > .info_box {
      flex: 1 1 180px;

      max-width: 225px;
      padding-right: 24px;

      & > p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark_grey);
      }

      & > a {
        font-weight: 500;
        font-size: 14px;
        color: var(--blue);
        text-decoration: underline;
      }

      & > .loading {
        @include mixins.loading_placeholder();

        min-height: 50px;
      }
    }
  }
}
