@use '../mixins';

.login {
  background-color: #ffffff;
  height: 100vh;

  & > .login-header {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }

  & > .login-container {
    background: url('/assets/images/login_bg.jpeg') no-repeat 100% #ebf4f7;
    min-height: 500px;
    display: flex;
    justify-content: flex-end;

    & > .login-container-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 430px;

      & > .form-error {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.02em;
        margin-bottom: 1em;
        color: #fe0000;
      }

      & > .user-login {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.8em;
        color: #0097e5;
        text-transform: uppercase;
      }

      & > .access {
        font-weight: bold;
        font-size: 48px;
        color: #000000;
        margin-bottom: 12px;
      }

      & > .login-form {
        display: flex;
        flex-direction: column;

        & > .form-label {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #808080;
          margin-bottom: 8px;
        }

        & > .form-input {
          width: 311px;
          height: 48px;
          background: #ffffff;
          box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
          border-radius: 100px;
          margin-bottom: 16px;
          padding-left: 2ch;
        }

        & > .form-button {
          width: 138px;
          height: 48px;
          background-color: var(--light_blue);
          border-radius: 100px;
          margin: 24px 0;
          font-weight: 600;
          text-transform: uppercase;
          color: #ffffff;
          border: none;

          & > .loader {
            @include mixins.submit_loader;

            margin: 0.7rem auto;
          }
        }
      }

      .footer_btns {
        padding: 0;
        margin: 5px 0;
        color: var(--blue);
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
      }
    }

    & > .login-container-image {
      background: url('/assets/images/laptop-wDashboard.png') no-repeat 0;
      background-size: cover;
      width: 50%;
      height: auto;
    }
  }

  & * input {
    border: none;
    font-family: inherit;
    color: #808080;

    &:focus {
      outline: none;
    }
  }

  & * a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
}
