#popper {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: -1;

  &[data-show='true'] {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    z-index: 1;
  }

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }

  &[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }

  &[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }

  &[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
