.transition {
  width: 100%;
  height: 54px;
  background: linear-gradient(180deg, rgba(249, 249, 249, 0) 16.11%, #f9f9f9 47.31%);
}

.documentation__container {
  padding: 0 40px 40px;
  background-color: #f9f9f9;
  border-radius: 0px 0px 8px 8px;

  & > .header_info {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;

    .disclaimer {
      flex: 1;

      margin: 0;
      min-width: 150px;
      text-align: right;
      color: var(--status_error);
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.02em;
    }
  }

  & > .documentation_space {
    position: relative;
    width: 100%;

    padding: 24px;

    background-color: var(--white);
    border: 1px solid var(--medium_grey);
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  .tabs_container {
    & > .tab {
      border: 1px solid var(--medium_grey);
      border-bottom: none;
    }
  }
}

.details__container {
  display: grid;
  grid-template-areas: 'a b .' 'c b .';
  grid-gap: 2em;

  table {
    &:nth-child(2) {
      grid-area: c;
    }

    &:nth-child(3) {
      grid-area: b;
    }
  }
}
