@mixin prod_catalog__table {
  $border_radius: 8px;

  width: 100%;

  box-sizing: border-box;
  border: 1px solid var(--light_grey);
  border-radius: $border_radius;

  border-spacing: 0;

  td + td,
  th + th {
    border-left: 1px solid #e9e9e9;
  }

  & > thead {
    background-color: var(--ice_blue);

    border-bottom: 1px solid var(--light_grey);

    tr th {
      padding: 8px 12px;
      border-bottom: 1px solid var(--light_grey);

      font-weight: bold;
      text-align: left;
      font-size: 16px;
      color: var(--dark_grey);

      &:first-child {
        border-top-left-radius: $border_radius;
      }
      &:last-child {
        border-top-right-radius: $border_radius;
      }

      & > .header-cell {
        display: flex;
        align-items: center;

        .sorting {
          margin-left: 6px;
          display: inherit;
          flex-direction: column;

          & > svg {
            &:last-child {
              margin-top: 3px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: $border_radius;
          }
          &:last-child {
            border-bottom-right-radius: $border_radius;
          }

          .product_img {
            & > .smooth_image_wrapper img,
            .preview_icon {
              border-bottom-left-radius: $border_radius;
            }
          }
        }
      }

      & + tr {
        td {
          border-top: 1px solid #cad1e1;
        }
      }

      td {
        padding: 8px 12px;

        font-weight: 500;
        font-size: 14px;
        color: #747474;
      }
    }
  }
}
