@use '../mixins';

$card_width: 480px;
$grid_gap: 32px;

.homepage_container {
  padding: 35px;
  min-height: calc(100vh - 345px);

  & > h3 {
    margin: 0;
    color: var(--blue);
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1px;
  }

  .home_description {
    margin: 0;
    max-width: 800px;
    font-weight: 600;
    font-size: 18px;
    color: var(--medium_grey);
  }

  .sections {
    max-width: $card_width * 3 + $grid_gap * 2; // 3 columns

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: $grid_gap;

    margin-top: 46px;
  }

  .update_container {
    display: inline-block;

    margin-bottom: 20px;
    padding: 8px 16px;
    border-radius: 8px;

    color: var(--white);
    background-color: #40994e;

    font-weight: 500;
    font-size: 18px;

    .vip_update {
      font-weight: bold;
    }

    .dismiss {
      color: inherit;
      font-size: inherit;
      @include mixins.text_link_btn;
    }
  }
}

.page_card__container {
  flex: 0 1 $card_width;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 32px 16px;

  box-sizing: border-box;
  border: 1px solid var(--white);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .title {
    margin: 12px 0;
    font-weight: bold;
    font-size: 20px;
    color: var(--blue);
  }

  .description {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: var(--medium_grey);

    &.loading {
      @include mixins.loading_placeholder;

      width: 100%;
      height: 80px;
    }
  }

  .review {
    @include mixins.default_button(16px, 48px);

    margin-top: 16px;
    padding: 0 24px;
  }

  .qty__container {
    // ovewrite navbar styling properties
    width: 50px;
    font-size: 20px;
    right: -20px;
    border-width: 2px;
  }
}
