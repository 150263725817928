.login-container-form {
  $submit_font: 12px;

  & > p {
    max-width: 40ch;
    margin-top: -10px;
    margin-bottom: 24px;
    color: var(--dark_grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }

  .submit_btn {
    font-size: $submit_font;
    max-width: 200px;
    margin: 24px 0;
    padding: 0 40px;
    letter-spacing: 0.02em;
  }

  .submit_btn.loader {
    padding: 15px;

    & > div {
      width: $submit_font;
      height: $submit_font;
    }
  }
}

.reset_pwd {
  padding: 40px;
  background-color: #ebf4f7;

  & > section {
    display: block;
    margin: 0 auto;
    max-width: 65em;

    .reset_pwd__content {
      padding: 36px;

      & > h4 {
        margin: 0 0 30px;
        color: var(--blue);
        font-size: 18px;
        letter-spacing: 1px;
      }
    }
  }
}
