@use "../mixins";

.read_more {
  .read_more__title {
    margin: -1px;
    padding: 16px;
    background-color: var(--light_blue);
    border-radius: 8px 8px 0px 0px;

    h1 {
      margin: 0;
      color: var(--white);
      font-weight: 600;
      font-size: 18px;
    }
  }

  .read_more__content {
    max-width: 700px;
    padding: 20px 30px;

    p {
      margin: 0;
      font-size: 14px;
      color: var(--dark_grey);
      white-space: pre-wrap;
      margin-bottom: 24px;
    }

    button {
      display: block;
      margin: 0 auto;

      width: 130px;
      @include mixins.default_button(16px);
    }
  }
}
