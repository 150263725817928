@use '../mixins';

.terms_conditions__container {
  & > .content {
    padding: 20px 40px;
    & > p {
      max-width: 600px;
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: var(--dark_grey);
    }

    & > .terms_conditions {
      max-width: 569px;
      min-height: 200px;
      max-height: 300px;
      overflow-y: auto;

      padding: 10px 18px;
      margin: 20px 0;

      border: 1px solid var(--medium_grey);
      border-radius: 8px;

      & > * {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: var(--dark_grey);
      }
    }

    & > .error {
      margin: 8px 0;

      color: var(--status_error);
      font-size: 13px;
      text-align: center;
    }

    & > .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .clickwrap_checkbox {
        cursor: not-allowed;

        & > .checkbox {
          margin-right: 6px;

          input[type='checkbox'] {
            display: none;

            &:checked + span {
              background-color: var(--light_blue);
              border-color: var(--light_blue);
            }

            &:disabled + span {
              border: 2px solid var(--medium_grey);
            }
          }

          span {
            display: inline-grid;
            place-items: center;

            width: 15px;
            height: 15px;
            background-color: var(--white);
            border-radius: 4px;
            border: 2px solid var(--light_blue);
          }
        }

        & > span {
          font-size: 13px;
        }
      }

      .clickwrap_checkbox.enabled {
        cursor: pointer;
      }

      & > .continue {
        @include mixins.default_button(16px, 16px);

        min-width: 170px;
        margin-top: 24px;
        padding: 16px 40px;
        letter-spacing: 0.02em;
      }

      & > .continue.loader {
        padding: 11px 40px;
        & > div {
          @include mixins.submit_loader;
        }
      }
    }
  }
}

// Design doesn't show proper margins/paddings or content
// alignment. Thus, using an arbitrary arrangement.
.terms-and-conditions-page {
  &__container {
    min-height: calc(100vh - 245px);
    background-color: var(--white);

    > .timestamp {
      font-weight: bold;
      font-size: 18px;
      color: var(--orange);
      padding: 16px 30px 0;
      text-transform: uppercase;
    }

    > .content {
      padding: 20px 16% 60px;

      & > * {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        color: var(--grey);
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 24px;
        color: var(--orange);
      }
    }
  }
}
